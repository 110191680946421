* {
  box-sizing: border-box;
}

body {
  font-family: Lato, sans-serif;
  margin: 20px;
  padding: 0;
  background-color: #f1e7da;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

.root {
  position: relative;
}

.header h2 {
  width: 326px;
  text-align: center;
}

.reset-container {
  visibility: hidden;
  position: absolute;
  top: 264px;
  left: 55px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  line-height: 48px;
  height: 48px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 48px;
}

.board {
  position: absolute;
  top: 55px;
  left: 15px;
  border: 1px black solid;
  padding: 10px;
  border-radius: 10px;
  background-color: #b9e4d3;
}

.computer-strength {
  position: absolute;
  top: 55px;
  left: 195px;
  border: 1px black solid;
  padding: 10px;
  border-radius: 10px;
  background-color: #b9e4d3;
}

.computer-strength p {
  margin: 0;
  padding: 3px 10px 7px;
}

.computer-strength label {
  display: block;
  padding: 4px;
}

.computer-strength label input {
  margin-right: 10px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .header, .header h2 {
    width: 100%;
  }

  .board {
    position: static;
    width: 290px;
    margin: 0 auto;
  }

  .computer-strength {
    position: static;
    font-size: 18px;
    width: 240px;
    margin: 0 auto;
  }

  .square {
    font-size: 61px;
    line-height: 90px;
    height: 90px;
    width: 90px;
  }

  .reset-container {
    position: static;
    width: 160px;
    display: block;
    visibility: hidden;
    margin: 20px auto;
  }

  .reset-container button {
    width: 100%;
    font-size: 24px;
    padding: 5px 10px;
    border: 2px solid gray;
    border-radius: 8px;
  }

  .status {
    font-size: 18px;
  }
}

